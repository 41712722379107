import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogTemplate.tsx";
import SEO from "Components/core/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="What I Consider When Writing CSS" description="I pick my own brain and dig into what I Consider when writing CSS" mdxType="SEO" />
    <p>{`At a high level, all of these considerations break down into two fronts. The Users download and the Developer ergonomics.`}</p>
    <p>{`For the users download we want to deliver only the CSS necessary for what they are viewing. And for it to be built on clean HTML.`}</p>
    <p>{`For the Developers ergonomics we want to break CSS down into manageable chunks, create separation on features and allow reusability. And maybe most importantly, we want our CSS solution to scale and allow for growth with the project.`}</p>
    <h2>{`Write only what you need and writing it once`}</h2>
    <p>{`CSS is flexible, and allows you to do so much. But there is danger in the large amount of flexibility afforded by CSS.`}</p>
    <p>{`When I write CSS I don't want to repeat myself more than I have to. But there is a balancing act between writing specific CSS for a core feature and general CSS for generic features.`}</p>
    <p>{`The classic example is button styles. Buttons need to be consistent and a Developer should be able to update all buttons in one location. When a button is styled on a per-feature bases this can lead to CSS getting repeated in each feature.`}</p>
    <p>{`This repetition can also lead to inconsistent button designs as well as problems updating it in the future.`}</p>
    <h2>{`Don't let tools bloat your final CSS`}</h2>
    <p>{`Tools create an abstraction away from your final CSS. Which has greatly helped the developer ergonomics around CSS. Especially when you live in a world of browser prefix's. However this abstraction hides the CSS you're creating.`}</p>
    <p>{`Sass for example is a popular CSS preprocessor with features like mixin's, variables and loops. But there are times when the distance from CSS can lead to large amounts of repeated CSS with very minimal developer ergonomic gain.`}</p>
    <p>{`To demonstrate some of the CSS bloat I have seen I put together two examples on SassMister (SassMister being a playground for SASS that compiles it in the browser).`}</p>
    <p>{`The first example shows mixins causing repeated CSS vs placeholders expanding only the CSS selector. `}<a parentName="p" {...{
        "href": "https://www.sassmeister.com/gist/363ce20038ed5c3e4adc7ae12229a390"
      }}>{`https://www.sassmeister.com/gist/160390ca9b723bbf08f1d000fa2fa2a7`}</a></p>
    <p>{`The second shows the each directive adding svg flag backgrounds. Which I have seen cause over 1000 lines of repeated CSS in live Production code. `}<a parentName="p" {...{
        "href": "https://www.sassmeister.com/gist/160390ca9b723bbf08f1d000fa2fa2a7"
      }}>{`https://www.sassmeister.com/gist/160390ca9b723bbf08f1d000fa2fa2a7`}</a></p>
    <p>{`FYI: If the CSS doesn't load, just put a space into the SASS terminal.`}</p>
    <h2>{`Try not to let CSS Dictate your HTML`}</h2>
    <p>{`First off, a cavate, unfortunately this can't always be avoided. Technologies like Grid and Flex have reduced the need for extra HTML scaffolding dramatically, but its not always avoidable.`}</p>
    <p>{`This doesn't mean you should let CSS dictate the HTML you write. Treat HTML as a first class citizen, it is a language in its own right. Assistive technologies and search engine crawlers rely on it to understand your site. Two very important parts of any website. Treat it with respect and you will reap the rewards.`}</p>
    <h2>{`Be aware of your Media Queries`}</h2>
    <p>{`In my experience, media queries get more attention spent on the Developer ergonomic front, then in the User download front. The primary reason I say this is that media queries can be dynamically added to the page using the link media attribute.`}</p>
    <p>{`This means that all of your CSS that you're using to make your site look nice on desktop and tablet, doesn't need to be downloaded by phone Users. So a clean media query strategy is a must for any project.`}</p>
    <p>{`You also only need 1 media selector for each size you're targeting. Which hasn't been the common strategy I have come across.`}</p>
    <p>{`The most common strategy I come across is media queries being added into the same file as the features styles. Leading to repeated media queries across multiple features. Essentially needlessly repeated CSS.`}</p>
    <h2>{`Think About the Future (But not too much)`}</h2>
    <p>{`Things change, and this needs to be part of the approach when implementing CSS. If a button design updates brand wide, it should update site wide in one easy place. And If a current feature has to fit into a new page it should be easily extended to achieve the new requirements.`}</p>
    <p>{`All of this can be done by brute forced of course. But creating a clear way of working with CSS that stays predictable and maintainable is the primary success factor for any CSS system.`}</p>
    <p>{`Scale in CSS is all about the future features not getting in the way of the past features, and a strategy that doesn't lead to a poor User download or a frustrating Developer experience.`}</p>
    <h2>{`In Closing`}</h2>
    <p>{`CSS is fun, complex, and has a lot to consider. These are the abstract ideas that I like to think about when writing CSS. Different CSS mythologies can help guide you in avoiding these issues. And some tools can just make them not a problem for the User. Just try to be aware because CSS won't yell at you, its that good friend that just helps you out without mentioning it. Even if its harming the friendship.`}</p>
    <p>{`If you have any thoughts do reach out to me on twitter @LiamMyles92`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      